import { IonButton, IonCard, IonCardContent, IonCardHeader, IonRow, IonCol, IonIcon, IonText, 
    IonNote, useIonToast, IonBadge } from "@ionic/react";
import { arrowRedoOutline, cart, cartOutline, heart, heartOutline, alertSharp, bookmark, bookmarkOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { addToCart } from "../data/CartStore";
import { addToFavourites, FavouritesStore } from "../data/FavouritesStore";
import styles from "./ProductCard.module.css";
import { encodeId } from "../utils/encoding";

const ProductCard = props => {

    const { product, category, index, cartRef } = props;
    const favourites = FavouritesStore.useState(s => s.product_ids);

    const productCartRef = useRef();
    const productFavouriteRef = useRef();
    const [ isFavourite, setIsFavourite ] = useState(false);

    useEffect(() => {

        const tempIsFavourite = favourites.find(f => f === `${ category.slug }/${ product.id }`);
        setIsFavourite(tempIsFavourite ? true : false);
    }, [props.product, favourites]);

    const addProductToFavourites = (e, categorySlug, productID) => {

        e.preventDefault();
        e.stopPropagation();
        addToFavourites(categorySlug, productID);


        productFavouriteRef.current.style.display = "";
        productFavouriteRef.current.classList.add("animate__fadeOutTopRight");

        setTimeout(() => {
            if (productCartRef.current) {
                productFavouriteRef.current.classList.remove("animate__fadeOutTopRight");
                productFavouriteRef.current.style.display = "none";
            }
        }, 500);
    }

    const [presentToast] = useIonToast();

    const addProductToCart = (e, categorySlug, productID) => {

        if(product.delivery_locations.length < 1){
            presentToast({
				message: "This product does not have a delivery location.",
				icon: alertSharp,
				color: 'danger',
				buttons: [
					{
						text: 'Dismiss',
						role: 'cancel'
					}
				]
			  });
              return;
        }

        e.preventDefault();
        e.stopPropagation();

        productCartRef.current.style.display = "";
        productCartRef.current.classList.add("animate__fadeOutUp");

        setTimeout(() => {

            cartRef.current.classList.add("animate__tada");
            addToCart(categorySlug, productID);

            setTimeout(() => {
                
                cartRef.current.classList.remove("animate__tada");
                productCartRef.current.style.display = "none";
            }, 500);
        }, 500);
    }

    return (

        <IonCol sizeSm="6" sizeMd="3" key={ `category_product_list_${ index }`}>
            <IonCard routerLink={ `/category/${ category.slug }/${ encodeId(product.id) }`} className={ styles.categoryCard }>
                <IonCardHeader className={ styles.productCardHeader }>
                    <div className={ styles.productCardActions }>
                        <IonIcon className={ styles.productCardAction }  color={ isFavourite ? "danger" : "medium" } 
                            icon={ isFavourite ? bookmark : bookmarkOutline } onClick={ e => addProductToFavourites(e, category.slug, product.id) } />
                        <IonIcon ref={ productFavouriteRef } style={{ position: "absolute", display: "none" }} 
                            className={ `${ styles.productCardAction } animate__animated` } color="danger" icon={ bookmark } />
                        
                    </div>
                    <img src={product.images?.length > 0 ? `${process.env.REACT_APP_PRODUCT_ASSETS}/${product.images[0]}` : `${process.env.PUBLIC_URL}/assets/no_image_available.jpg`} className={styles.productImage} alt="product pic" />
                    {product.fullPriceDisplay && product.price < product.fullPriceDisplay ? (
                        <div className={styles.discountBadge}>
                            <IonText className="ion-padding-vertical">
                                { (100 - Math.round((product.price / product.fullPriceDisplay) * 100)) }% off
                                <span style={{ textDecoration: "line-through", paddingLeft: "2px", color: "red", textWrap: "nowrap" }}>
                                    Nu. { product.fullPriceDisplay }
                                </span>
                            </IonText>
                        </div>
                    ) : null }
                    <div style={{ position: "absolute", top: 50, right: 0}}>
                        { product.stock < 5 && product.stock > 0 ? (
                            <IonBadge color="warning" className="ion-padding">
                                Only { product.stock } left
                            </IonBadge>
                        ) : null }
                        { product.stock < 1 ? (
                            <IonBadge color="danger" className="ion-padding">
                                Out of stock
                            </IonBadge>
                        ) : null }
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p className="ion-text-wrap" style={{ height: '40px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {product.name}
                        </p>
                    </div>
                </IonCardHeader>

                <IonCardContent className={ styles.categoryCardContent }>
                    { /* category.slug == "clothes" && 
                        <sub className="ion-padding" style={{ textDecoration: "line-through" }}>
                            Nu. { product.price + 100 }
                        </sub>
                        */
                    }
                    
                    <div className={ styles.productPrice } style={{ display: "flex",  justifyContent: "space-between" }}>
                        <IonButton fill="outline" color="drukre" style={{ textTransform: "none" }}>
                            Nu. {product.price}
                        </IonButton>
                        <IonButton color="drukre" fill="outline" 
                            onClick={ e => addProductToCart(e, category.slug, product.id) }
                            disabled={product.stock < 1}>
                            <IonIcon icon={ cartOutline } />
                        </IonButton>

                        <IonIcon ref={ productCartRef } icon={ cart } color="dark" style={{ position: "absolute", display: "none", fontSize: "3rem" }} className="animate__animated" />
                    </div>
                </IonCardContent>
            </IonCard>
        </IonCol>
    );
}

export default ProductCard;