import {
    IonBadge, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon,
    IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel, IonList, IonModal, IonNote, IonPage, IonProgressBar, IonRow,
    IonSearchbar, IonTitle, IonToolbar,
    useIonLoading,
    useIonToast, useIonModal,
    IonThumbnail,
    IonAvatar,
    IonImg,
    IonSegmentButton,
    IonSegment,
    IonCard,
    IonFooter,
    IonItemDivider,
    IonRadioGroup,
    IonRadio
} from "@ionic/react";
import { cart, cartOutline, checkmark, chevronBackOutline, personCircle, 
    search, searchOutline, alertSharp, paperPlane, star, starOutline, 
    copySharp} from "ionicons/icons";
import { useEffect, useRef, useState, useContext, useLayoutEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Clipboard } from "@capacitor/clipboard";
import { OverlayEventDetail } from '@ionic/core/components';

import AuthContext from "../UserContext";

import axiosCall from '../data/axios';

import { CategoryStore } from '../data/CategoryStore';

import PaymentForm from '../components/PaymentGateway';

import { format } from 'date-fns';
import React from "react";
const formatTimestamp = (timestamp: any) => {
    return format(new Date(timestamp), 'PPpp'); // PPpp gives a readable format like June 6, 2024 at 11:37 AM
};

const UpdateModal = ({ selectedOrder, dismiss }: { selectedOrder: any, dismiss: (data?: any | null | undefined | number, role?: string) => void }) => {
    const inputRef = useRef<HTMLIonInputElement>(null);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle> Reach out to seller</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem className="ion-padding">
                    For contacting the seller about the order, type the mesaage and click the send button. 
                </IonItem>
                <IonInput ref={inputRef} labelPlacement="floating" fill="outline" label="Enter a message for seller." />
                <IonItemDivider/>
                <IonItem>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton color="medium" onClick={() => dismiss(null, 'cancel')}>
                                Cancel
                            </IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonButton color="drukre" onClick={() => dismiss({ order: selectedOrder, msg: inputRef.current?.value }, 'confirm')} strong={true}>
                                Send
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonItem>
            </IonContent>
        </IonPage>
    );
};

const ReviewModal = ({ selectedOrder, dismiss }: { selectedOrder: any, dismiss: (data?: any | null | undefined | number, role?: string) => void }) => {
    const inputRef = useRef<HTMLIonInputElement>(null);
    const [rating, setRating] = useState(0);
    const maxRating = 5;
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Rate the purchase</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem className="ion-padding">
                    Add a rating, your comment and click the submit button. 
                </IonItem>
                <IonItemDivider>Please note only the last rating will be saved.</IonItemDivider>
                <IonRadioGroup value={rating} onIonChange={e => setRating(e.detail.value)}>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '10px', marginTop: '10px' }}>
                    {[...Array(maxRating)].map((_, index) => (
                        <IonItem key={index} lines="none" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '20%' }}>
                            <IonIcon
                                icon={rating > index ? star : starOutline}
                                onClick={() => setRating(index + 1)}
                                style={{ fontSize: '24px', cursor: 'pointer' }}
                            />
                            <IonNote className="ion-padding-top">{index + 1}</IonNote>
                        </IonItem>
                    ))}
                    </div>
                </IonRadioGroup>

                <IonInput ref={inputRef} labelPlacement="floating" fill="outline" label="Enter a comment." />
                <IonItemDivider/>
                <IonItem>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton color="medium" onClick={() => dismiss(null, 'cancel')}>
                                Cancel
                            </IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonButton color="drukre" onClick={() => dismiss({ order: selectedOrder, msg: inputRef.current?.value, rating: rating }, 'confirm')} strong={true}>
                                Submit
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonItem>
            </IonContent>
        </IonPage>
    );
};

const RetryPayment = ({ orderNo, purchaserData, total, dismiss }: { orderNo: any, purchaserData: any, total:number, 
    dismiss: (data?: any | null | undefined | number, role?: string) => void }) => {
    const payLoad = {
        order_no: orderNo
    };
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Payment</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <PaymentForm purchaserData={purchaserData} payLoad={payLoad} onSuccess={null} api= '/api/orders' total={total} />
            </IonContent>
        </IonPage>
    );
};

const MyPurchases = () => {

    const history = useHistory();

    const { authValues } = useContext(AuthContext);

    useLayoutEffect(() => {
        if (!authValues.authenticated) {
            history.push('/login');
        }
    }, [authValues]);

    const categories = CategoryStore.useState(s => s.categories);

    const [ordersCheck, setOrdersCheck] = useState(false);

    const [buffer, setBuffer] = useState(0.06);

    const [progress, setProgress] = useState(0);

    const [myOrders, setMyOrders] = useState<any>([]);

    const fetchOrders = async (filter: any) => {

        setProgress(0);
        setBuffer(0.06);

        // Set progress to 25% when fetching starts
        setProgress(0.25);

        await axiosCall("/api/my-purchases", 'post', filter, true)
            .then((res) => {

                let orders = res.data;

                setMyOrders(orders);

                setOrdersCheck(true);

                // Increment progress by 25% every second after fetching is complete
                const interval = setInterval(() => {
                    setProgress((prevProgress) => prevProgress >= 1 ? 1 : prevProgress + 0.25);
                    setBuffer((prevBuffer) => prevBuffer >= 1 ? 1 : prevBuffer + 0.25);
                }, 1000);

                // Clear interval when progress reaches 100%
                if (progress >= 1) {
                    clearInterval(interval);
                }

            })
            .catch((error) => {
                //setIserror(true);
                setOrdersCheck(true);
            });
    }

    const [filterQuery, setFilterQuery] = useState({});

    useEffect(() => {

        var q = {};

        if (filterQuery)
            q = Object.assign(q, filterQuery);

        fetchOrders(q);

        if (`${process.env.REACT_APP_ENVIRONMENT}` == 'development') {

        } else {
            //setMarkers(initialMarkers);
            //setProductsCheck(true);
        }

    }, [filterQuery]);

    const [selectedOrder, setSelectedOrder] = useState({});

    const [presentingElement, setPresentingElement] = useIonModal(UpdateModal, {
        selectedOrder, dismiss: (data: string, role: string) => setPresentingElement(data, role)
    });

    const messaging = (orderId: any, action: any) => {
        setSelectedOrder({ id: orderId, action: action });
        presentingElement({
            initialBreakpoint: 0.5,
            breakpoints: [0, 0.4, 0.7, 1],
            backdropBreakpoint: 0,
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'confirm') {

                    afterSave(ev.detail.data);

                }
            }
        });
    };

    const [spinnerPresent, spinnerDismiss] = useIonLoading();
    const [presentToast] = useIonToast();

    function afterSave(data: any) {

        setPresentingElement();

        spinnerPresent({
            message: 'Sending. Please wait...',
            spinner: 'bubbles',
            animated: true,
            cssClass: 'drukre-loading'
        });

        axiosCall("/api/orders/message", "post", data, true)
            .then((res) => {
                //setMyMarkersCheck(false);
                spinnerDismiss();
                presentToast({
                    message: 'Email Sent Successfully!',
                    icon: checkmark,
                    color: 'success',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
                });
            })
            .catch((error) => {
                //setMessage("Auth failure! Please create an account");
                //setIserror(true);
                spinnerDismiss();
                presentToast({
                    message: error.message,
                    icon: alertSharp,
                    color: 'danger',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
                });
            });
    }

    const [presentReviewal, setPresentReviewal] = useIonModal(ReviewModal, {
        selectedOrder, dismiss: (data: string, role: string) => setPresentReviewal(data, role)
    });

    const reviewal = (orderId: any, action: any) => {
        setSelectedOrder({ id: orderId, action: action });
        presentReviewal({
            initialBreakpoint: 0.5,
            breakpoints: [0, 0.4, 0.7, 1],
            backdropBreakpoint: 0,
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'confirm') {

                    afterReview(ev.detail.data);

                }
            }
        });
    };

    function afterReview(data: any) {

        setPresentingElement();

        spinnerPresent({
            message: 'Saving. Please wait...',
            spinner: 'bubbles',
            animated: true,
            cssClass: 'drukre-loading'
        });

        axiosCall("/api/orders/review", "post", data, true)
            .then((res) => {
                //setMyMarkersCheck(false);
                spinnerDismiss();
                presentToast({
                    message: 'Order updated!',
                    icon: checkmark,
                    color: 'success',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
                });
            })
            .catch((error) => {
                //setMessage("Auth failure! Please create an account");
                //setIserror(true);
                spinnerDismiss();
                presentToast({
                    message: error.message,
                    icon: alertSharp,
                    color: 'danger',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
                });
            });
    }

    const [purchaserData, setPurchaserData] = useState<any>({});
    const [orderNo, setOrderNo] = useState('');
    const [total, setTotal] = useState(0);
    const [presentPayment, setPresentPayment] = useIonModal(RetryPayment, {
        orderNo, purchaserData, total, dismiss: (data: string, role: string) => setPresentReviewal(data, role)
    });

    const retryPayment = (orderId: any, total:number) => {
        setOrderNo(orderId);
        setTotal(total);
        setPurchaserData({
            isExistingUser: true,
            isUserFormValid: true,
            userData: authValues.user
        });
        presentPayment({
            initialBreakpoint: 0.5,
            breakpoints: [0, 0.4, 0.7, 1],
            backdropBreakpoint: 0,
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'confirm') {

                    //afterReview(ev.detail.data);

                }
            }
        });
    };

    const categoryFilter = (catId: any) => {
        let cat = categories.filter((f: any) => catId == f.id);
        return cat.length > 0 ? cat : [{ description: "" }];
    };

    const orderSteps = ["pending", "confirmed", "completed", "rejected"];

    const copyToClipboard = async (text: string): Promise<void> => {
        try {
            await Clipboard.write({
                string: text
            });
            presentToast({
                message: 'Copied to clipboard.',
                duration: 3000,
                color: 'success'
            });
        } catch (err) {
            console.error('Failed to copy text:', err);
            presentToast({
                message: 'Failed to copy text to clipboard.',
                duration: 3000,
                color: 'danger'
            });
        }
    };

    return (

        <IonPage id="orders-page">
            <IonHeader>
                <IonToolbar>

                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding ion-text-center">
                <IonToolbar>
                    {/*}
                    <IonSegment onIonChange={e => setFilterQuery({ status: e.detail.value })}>
                        <IonSegmentButton value="pending">
                            <IonLabel>Open Orders</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="confirmed">
                            <IonLabel>Closed Orders</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="rejected">
                            <IonLabel>Rejected Orders</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                    */}
                    My Purchases
                </IonToolbar>

                <IonGrid>
                    <IonRow>
                        <IonCol size-md="10" offset-md="1">
                            <IonRow>
                                <IonCol>
                                    <IonProgressBar color='drukre' buffer={buffer} value={progress}></IonProgressBar>
                                </IonCol>
                            </IonRow>
                            <IonRow class="ion-justify-content-center ion-hide-md-down">
                                <IonCol size="2">

                                </IonCol>
                                <IonCol sizeXs="12" sizeSm="6" sizeMd="6">
                                    Order Details
                                </IonCol>
                            </IonRow>
                            {myOrders.map((ord: any, i: any) => {
                                let purchaser_details = JSON.parse(ord.purchaser_details);
                                let delivery_location = JSON.parse(ord.delivery_location);
                                return (
                                    <IonCard key={i}>
                                        <IonRow>
                                            <IonCol style={{ display: "flex", justifyContent: "center", alignItems: "center" }} sizeMd="2">
                                                <IonAvatar>
                                                    <IonImg src={ord.inventory.images?.length > 0 ? `${process.env.REACT_APP_PRODUCT_ASSETS}/${ord.inventory.images[0]}` : `${process.env.PUBLIC_URL}/assets/no_image_available.jpg`} />
                                                </IonAvatar>
                                            </IonCol>
                                            <IonCol class="ion-text-left" sizeXs="12" sizeSm="6" sizeMd="6">
                                                Item Name: {ord.inventory.name} <br />
                                                Price: Nu. <IonBadge color="secondary">{ord.unit_price}</IonBadge> <br />
                                                Quantity: {ord.quantity} <br />
                                            </IonCol>
                                            <IonCol>
                                                Payment #: {ord.order.payment_number} <IonButton mode="md" onClick={() => copyToClipboard(ord.order.payment_number)}> <IonIcon icon={copySharp} /></IonButton><br />
                                                Order Number: {ord.order.order_number} <IonButton mode="md" onClick={() => copyToClipboard(ord.order.order_number)}> <IonIcon icon={copySharp} /></IonButton><br />
                                                Payment Method: {ord.order.payment_method} <br />
                                                Payment Status: <IonBadge color={ord.order.payment_status == 'unsuccessful' ? 'danger' : 'success'}> {ord.order.payment_status} </IonBadge><br />
                                                {ord.order.payment_status == 'unsuccessful' && ord.status !== "rejected" ? 
                                                    <IonButton mode="md" onClick={() => retryPayment(ord.order.order_number, parseInt(ord.order.total, 10))}>Retry Payment</IonButton>
                                                    : ''
                                                }
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                Sub Order #: {ord.sub_order_number} <IonButton mode="md" onClick={() => copyToClipboard(ord.sub_order_number)}> <IonIcon icon={copySharp} /></IonButton><br />
                                                Status: <IonBadge color="secondary"> {ord.status} </IonBadge><br />
                                                Location: {delivery_location?.address?.price ? delivery_location.address.dzo : delivery_location.location} <br />
                                                {delivery_location?.address?.price ? (
                                                    <>
                                                        Address: {delivery_location.address.address} 
                                                        <br /> Delivery Charge: Nu. <IonBadge color="secondary"> {delivery_location.address.price}</IonBadge>
                                                        <br/>
                                                    </>
                                                ) : ''} 
                                                Note to Seller: {ord.note} <br />
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                {/* Render steps with lines connecting them */}
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {orderSteps.map((step, index, array) => {
                                                        // Skip rendering "rejected" as a separate step
                                                        if (step === "rejected") return null;

                                                        // Determine if the current step is active or completed
                                                        const isCurrentOrCompleted = ord.status === step || (step === "completed" && ord.status === "rejected") || index < array.indexOf(ord.status);

                                                        return (
                                                            <React.Fragment key={index}>
                                                                {index !== 0 && (
                                                                    // Line element
                                                                    <div style={{
                                                                        height: '2px',
                                                                        backgroundColor: 'grey',
                                                                        flex: 1, // Take up available space
                                                                    }}></div>
                                                                )}
                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                    <div style={{
                                                                        width: '70px',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        display: 'flex',
                                                                        color: 'white',
                                                                        height: '35px',
                                                                        borderRadius: '10%',
                                                                        backgroundColor: isCurrentOrCompleted ? (ord.status === "rejected" ? "red" : "green") : 'grey',
                                                                        marginRight: '8px',
                                                                        marginLeft: index !== 0 ? '8px' : '0', // Add margin if it's not the first element
                                                                    }}>
                                                                        <small>
                                                                            {/* Display "REJECTED" if ord.status is "rejected", otherwise display the current step */}
                                                                            {ord.status === "rejected" && step === "completed" ? "REJECTED" : step.toUpperCase()}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                        <p style={{ fontSize: "small" }}>{formatTimestamp(ord.created_at)}</p>
                                        {ord.status != 'completed' ?
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton color="secondary" onClick={() => messaging(ord.id, 'confirmation')}>
                                                        <IonIcon icon={paperPlane} slot="start" />
                                                        Contact Seller
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        :
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton color="primary" onClick={() => reviewal(ord.id, 'reviewal')}>
                                                        <IonIcon icon={starOutline} slot="start" />
                                                        Review Seller
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        }
                                    </IonCard>
                                )
                            }
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default MyPurchases;