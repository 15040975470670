import { IonAvatar, IonBadge, IonButton, IonButtons, IonCardSubtitle, IonCol, IonContent, 
    IonFooter, IonHeader, IonIcon, IonImg, IonItem, IonItemOption, IonItemOptions, 
    IonItemSliding, IonLabel, IonList, IonNote, IonPage, IonRadioGroup, IonRadio,
    IonRow, IonTitle, IonToolbar, useIonToast, IonInput, IonSelect, IonSelectOption,
    IonGrid, IonTextarea, IonSegment, IonSegmentButton,
    IonItemDivider} from "@ionic/react";
import { cart, cartOutline, checkmarkSharp, chevronBackOutline, trashOutline, alertSharp,
    add, remove
 } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { CartStore, updateCartItem, removeFromCart } from "../data/CartStore";
import { ProductStore } from "../data/ProductStore";

import styles from "./CartProducts.module.css";
import Product from "./Product";
import { update } from "pullstate";

import { coreLocations } from '../data';
import { set } from "date-fns";
import { encodeId } from "../utils/encoding";

const CartProducts = () => {

    const history = useHistory();

    const cartRef = useRef();
    const products = ProductStore.useState(s => s.products);
    const productsFullyLoaded = ProductStore.useState(s => s.fullyLoaded);
    const shopCart = CartStore.useState(s => s.product_ids);
    const [cartProducts, setCartProducts] = useState([]);
    const [amountLoaded, setAmountLoaded] = useState(6);
    let productStocks = [];

    const [presentToast] = useIonToast();

    const [total, setTotal] = useState(0);

    const getCartProducts = () => {

        setCartProducts([]);
        setTotal(0);

        // Filter out items where tempProduct does not exist
        const filteredShopCart = shopCart.filter(product => {
            const categorySlug = product.categorySlug;
            const productID = product.productID;
            const tempCategory = products.filter(p => p.slug === categorySlug)[0];
            const tempProduct = tempCategory ? tempCategory.products.filter(p => parseInt(p.id) === parseInt(productID))[0] : null;
            var index = shopCart.indexOf(product);
            if(tempProduct !== undefined)
                return product;
            else {
                removeFromCart(index);
            }
        });

        filteredShopCart.forEach(product => {

            var cartItemParts = product;
            var categorySlug = cartItemParts.categorySlug;
            var productID = cartItemParts.productID;
            var deliveryLocID = cartItemParts.newDeliveryLocID ? parseInt(cartItemParts.newDeliveryLocID) : null;
            var dzongkhag = cartItemParts.dzo;
            var address = cartItemParts.address;
            var price = cartItemParts.price;
            var data = cartItemParts.data;
            var quantity = cartItemParts.quantity;
            var note = cartItemParts.note;
            var deliveryMode = cartItemParts.deliveryMode;

            const tempCategory = products.filter(p => p.slug === categorySlug)[0];
            const tempProduct = tempCategory.products.filter(p => parseInt(p.id) === parseInt(productID))[0];

            const tempCartProduct = {

                category: tempCategory,
                product: tempProduct,
                selected_location: deliveryLocID,
                selected_dzo: dzongkhag,
                address: address,
                delPrice: price,
                days: data?.days,
                quantity: quantity,
                note: note,
                deliveryMode: deliveryMode
            };

            setTotal(prevTotal => prevTotal + parseInt(tempProduct.price * quantity));
            price && setTotal(prevTotal => prevTotal + parseInt(price));
            setCartProducts(prevSearchResults => [...prevSearchResults, tempCartProduct]);
        });
    }

    useEffect(() => {

        if(productsFullyLoaded)
            getCartProducts();

    }, [shopCart, productsFullyLoaded]);

    const fetchMore = async (e) => {

        //	Increment the amount loaded by 6 for the next iteration
        setAmountLoaded(prevAmount => (prevAmount + 6));
        e.target.complete();
    }

    const removeProductFromCart = async (index) => {

        const slidingItem = document.querySelector(`#sliding-${index}`);
        if (slidingItem) {
            await slidingItem.closeOpened();
        }

        removeFromCart(index);
    }


    // Check if all delivery locations are selected before proceeding to checkout
    const handleCheckout = () => {

        const isDeliveryLocationSelected = cartProducts.map(product => product.selected_location !== null);

        if (isDeliveryLocationSelected.every(Boolean)) {
            history.push('/checkout');
        } else {
            presentToast({
				message: 'Please select either a pickup or a delivery location for every products.',
				icon: alertSharp,
				color: 'danger',
				buttons: [
					{
						text: 'Dismiss',
						role: 'cancel'
					}
				]
			  });
        }
    };
    
    const handleFreeLocations = (index, location) => {
        let item = shopCart[index];
        updateCartItem(index, location, null, null, null, item.data, item.quantity, item.note, 'pickup');
    };

    const handleAddress = (index, address) => {
        let item = shopCart[index];
        updateCartItem(index, null, item.dzo, address, item.price, item.data, item.quantity, item.note, 'delivery');
    };
    const handlePaidLocations = (index, dzongkhag, delLocID, price, data) => {
        let item = shopCart[index];
        updateCartItem(index, delLocID, dzongkhag, null, price, data, item.quantity, item.note, 'delivery');
    }

    const updateQuantity = (index, qty) => {
        let item = shopCart[index];
        let newQty = Math.max(qty, 1);
        newQty = Math.min(newQty, productStocks[index]);
        updateCartItem(index, item.newDeliveryLocID, item.dzo, item.address, item.price, item.data, newQty, item.note);
    }

    const incrementQuantity = (index) => {
        let item = shopCart[index];
        let newQty = Math.max(item.quantity+1, 1);
        newQty = Math.min(newQty, productStocks[index]);
        updateCartItem(index, item.newDeliveryLocID, item.dzo, item.address, item.price, item.data, newQty, item.note);
    };

    const decrementQuantity = (index) => {
        let item = shopCart[index];
        let newQty = Math.max(item.quantity - 1, 1);
        newQty = Math.min(newQty, productStocks[index]);
        updateCartItem(index, item.newDeliveryLocID, item.dzo, item.address, item.price, item.data, newQty, item.note);
    };

    const handleNote = (index, note) => {
        let item = shopCart[index];
        updateCartItem(index, null, null, item.address, item.price, item.data, item.quantity, note);
    };

    // Function to process delivery locations and return select options
    function getSelectOptions(deliveryLocations) {
        return deliveryLocations.filter(delivery_loc => delivery_loc.charges > 0).flatMap(dzo => {
            const locationsArray = JSON.parse(dzo.location); // Parse the JSON string to an array
            return locationsArray.map((coreLocKey, clIdx) => {
                const label = Object.entries(coreLocations).find(([key, value]) => key === coreLocKey)?.[1];
                return {
                    key: `option-${dzo.id}-${clIdx}`,
                    value: coreLocKey,
                    label: label,
                    delLocID: dzo.id,
                    price: dzo.charges,
                    data: JSON.parse(dzo.data)
                };
            });
        });
    }

    function setDeliveryMode(index, mode) {
        let item = shopCart[index];
        updateCartItem(index, item.newDeliveryLocID, null, null, 0, item.data, item.quantity, item.note, mode);
    }

    return (

        <IonPage id="category-page" className={styles.categoryPage}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="dark" routerLink="/" routerDirection="back">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Categories
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Cart</IonTitle>

                    <IonButtons slot="end">
                        <IonBadge color="dark">
                            {shopCart.length}
                        </IonBadge>
                        <IonButton color="dark">
                            <IonIcon ref={cartRef} className="animate__animated" icon={cart} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>

                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="dark" routerLink="/" routerDirection="back">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Categories
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Cart</IonTitle>

                    <IonButtons slot="end" class="ion-padding-left">
                        <IonButton color="drukre" routerLink="/cart">
							{ shopCart.length }
							<IonIcon ref={ cartRef } className="animate__animated" icon={ cartOutline } />
						</IonButton>
					</IonButtons>
                </IonToolbar>

                <IonRow className="ion-text-center">
                    <IonCol size="12" className="ion-padding-horizontal">
                        <p>{cartProducts && cartProducts.length} {(cartProducts.length > 1 || cartProducts.length === 0) ? " products" : " product"}</p>
                        <IonNote>If you need to remove an item, please swipe left on the item and click the trash icon.</IonNote>
                    </IonCol>
                </IonRow>

                <IonList>
                    {cartProducts && cartProducts.map((product, index) => {
                        productStocks[index] = product.product.stock;
                        //if ((index <= amountLoaded)) {
                            return (
                                <IonItemSliding key={`sliding-${index}`} id={`sliding-${index}`} className={styles.cartSlider}>
                                    <IonItem key={`item-${index}`}>
                                        <IonGrid>
                                            <IonRow key={`sliding1-${index}`}>
                                                <IonCol size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12">
                                                    <IonItem key={`sliding2-${index}`}lines="none" detail={false} className={styles.cartItem}>
                                                        <a href={`/category/${product.category.slug}/${encodeId(product.product.id)}`} title="Go to item">
                                                            <IonAvatar>
                                                                <IonImg src={product.product.images?.length > 0 ? `${process.env.REACT_APP_PRODUCT_ASSETS}/${product.product.images[0]}` : `${process.env.PUBLIC_URL}/assets/no_image_available.jpg`} />
                                                            </IonAvatar>
                                                        </a>
                                                        <IonLabel className="ion-padding-start ion-text-wrap">
                                                            <p>{product.category.name}</p>
                                                            <h4>{product.product.name}</h4>
                                                        </IonLabel>

                                                        <div className={styles.cartActions}>
                                                            <IonBadge color="danger">Nu. {product.product.price}</IonBadge>
                                                        </div>
                                                    </IonItem>
                                                </IonCol>

                                                <IonCol size-xl="2" size-lg="2" size-md="2" size-sm="12" size-xs="12">
                                                    <IonNote>Quantity</IonNote>
                                                    <IonInput labelPlacement="stacked" value={product.quantity}
                                                        onIonInput={(e) => updateQuantity(index, Number(e.target.value))} className="ion-text-center">
                                                        <IonButton slot="start" aria-label="Subtract" onClick={() => decrementQuantity(index)} >
                                                            <IonIcon icon={remove}></IonIcon>
                                                        </IonButton>
                                                        <IonButton slot="end" aria-label="Add" onClick={() => incrementQuantity(index)} >
                                                            <IonIcon icon={add}></IonIcon>
                                                        </IonButton>
                                                    </IonInput>
                                                </IonCol>
                                                <IonCol size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12">
                                                    <IonSegment value={product.deliveryMode} onIonChange={e => {setDeliveryMode(index, String(e.detail.value) || 'pickup'); }}>
                                                        <IonSegmentButton value="pickup" checked={product.deliveryMode === 'pickup' || !product.deliveryMode}>
                                                            <IonLabel>Free Pickup</IonLabel>
                                                        </IonSegmentButton>
                                                        {product.product.delivery_locations.some(delivery_loc => delivery_loc.charges > 0) && (
                                                        <IonSegmentButton value="delivery" checked={product.deliveryMode === 'delivery'}>
                                                            <IonLabel>Delivery</IonLabel>
                                                        </IonSegmentButton>
                                                        )}
                                                    </IonSegment>
                                                    {(product.deliveryMode === 'pickup' || !product.deliveryMode)
                                                        && product.product.delivery_locations.some(delivery_loc => delivery_loc.charges < 1) && (
                                                        <div className="ion-padding">
                                                            <IonNote className="ion-padding-start">Free Pick Up Location</IonNote>
                                                            <IonRadioGroup labelPlacement="floating" fill="outline" value={product.selected_location} onIonChange={e => handleFreeLocations(index, e.detail.value)}>
                                                                {product.product.delivery_locations.filter(delivery_loc => delivery_loc.charges < 1).map((delivery_loc, ii) => (
                                                                    <IonItem className="ion-padding-start" key={`item-${index}-${ii}`}>
                                                                        <IonLabel>{delivery_loc.location} <IonNote> {JSON.parse(delivery_loc.data)?.days ? ` - Pickup ready within ${JSON.parse(delivery_loc.data)?.days} day(s)` : null}</IonNote></IonLabel>
                                                                        <IonRadio slot="end" value={delivery_loc.id} />
                                                                    </IonItem>
                                                                ))}
                                                            </IonRadioGroup>
                                                        </div>
                                                    )}
                                                    {product.deliveryMode === 'delivery' && product.product.delivery_locations.some(delivery_loc => delivery_loc.charges > 0) && (
                                                     <div className="ion-padding">
                                                        <IonNote className="ion-padding-start">Delivery on Extra Cost</IonNote>
                                                        <IonRow key={`sliding4-${index}`}>
                                                            <IonCol size="4">
                                                                <IonSelect 
                                                                    value={product.selected_dzo} onIonChange={e => {
                                                                        const selectedOption = getSelectOptions(product.product.delivery_locations).find(option => option.value === e.detail.value);
                                                                        if (selectedOption) {
                                                                            handlePaidLocations(index, e.detail.value, selectedOption.delLocID, selectedOption.price, selectedOption.data);
                                                                        }
                                                                    }} 
                                                                    labelPlacement="floating" fill="outline" 
                                                                    placeholder="Select Location"
                                                                >
                                                                    <IonSelectOption value={null}>Select Locality</IonSelectOption>
                                                                    {getSelectOptions(product.product.delivery_locations).map(({ key, value, label, delLocID, price, data }) => (
                                                                        <IonSelectOption key={key} value={value}>
                                                                            {label}
                                                                        </IonSelectOption>
                                                                    ))}
                                                                </IonSelect>
                                                            </IonCol>
                                                            <IonCol size="8">
                                                                <IonInput value={product.address} 
                                                                    placeholder="Enter address here" type="text" 
                                                                    disabled={product.selected_dzo == null} 
                                                                    onIonInput={e => {
                                                                        // You need to have delLocID and price available here, possibly from component state
                                                                        handleAddress(index, e.detail.value);
                                                                    }} 
                                                                    labelPlacement="floating" fill="outline"
                                                                    label="Enter Your Address">
                                                                </IonInput>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow key={`sliding6-${index}`}>
                                                            <IonCol size="6">
                                                                {product.days ? <>Delivery Time : <IonNote>Within {product.days} day(s)</IonNote></> : null}
                                                            </IonCol>
                                                            <IonCol size="6">
                                                                {product.delPrice ?
                                                                    <>
                                                                        Delivery Charge &nbsp;<IonBadge className="ion-float-right" color="danger">Nu. {product.delPrice}</IonBadge>
                                                                    </>
                                                                : null}
                                                            </IonCol>
                                                        </IonRow>
                                                    </div>
                                                    )}
                                                    {product.product.delivery_locations.length < 1 ? 
                                                        <IonLabel className="ion-padding-start"> - No pickup locations</IonLabel>
                                                    : null}
                                                </IonCol>
                                                <IonCol size-md="8" offset-md="2">
                                                    <IonNote className="ion-padding-start">Note to Seller</IonNote>
                                                    <IonRow>
                                                        <IonCol size="12">
                                                            <IonTextarea label="Note to Seller" labelPlacement="floating" fill="outline" 
                                                                placeholder="A note can be anything such as product specs, gifting note, etc..."
                                                                value={product.note} onIonInput={(e) => handleNote(index, e.target.value)}
                                                                counter={true}
                                                                maxlength={200}
                                                                autoGrow={true}
                                                                counterFormatter={(inputLength, maxLength) => `${maxLength - inputLength} characters remaining`}
                                                            ></IonTextarea>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>
                                    <IonItemOptions side="end">
                                        <IonItemOption color="danger" style={{ paddingLeft: "1rem", paddingRight: "1rem" }} onClick={() => removeProductFromCart(index)}>
                                            <IonIcon icon={trashOutline} />
                                        </IonItemOption>
                                    </IonItemOptions>
                                </IonItemSliding>
                            );
                        //}
                    })}
                </IonList>
            </IonContent>

            <IonFooter>
                <div className={styles.cartCheckout}>
                    <IonCardSubtitle>Nu. {total.toFixed(2)}</IonCardSubtitle>

                    <IonButton color="drukre" onClick={() => handleCheckout()} disabled={cartProducts.length < 1}>
                        <IonIcon icon={checkmarkSharp} />&nbsp;Checkout
                    </IonButton>
                </div>
            </IonFooter>
        </IonPage>
    );
}

export default CartProducts;